var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"product-list"}},[_c('b-container',{staticClass:"product-list--margin"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"products-section__title"},[_c('h3',{staticClass:"products-section__title--style"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h4',{staticClass:"products-section__subtitle--style"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),_c('div',{staticClass:"products-section__list d-flex flex-wrap flew-row justify-content-around px-4"},[_c('product-item',{attrs:{"productID":"1","image":"compresor-1.png","title":"Compresores de tornillo lubricado de transmisión por poleas y bandas para talleres, centros automotrices o pequeños negocios.","items":[
                            { message: 'Hasta 141 CFM (30 hp) Modelos SXC hasta AS.' }, 
                            { message: 'Hasta 217 psig.' }, 
                            { message: 'Versión “T” con secador refrigerativo integrado y Aircenter con secador y tanque integrado.' }, 
                            { message: 'Versiones con variador de frecuencia SFC.' }
                        ]}}),_c('product-item',{attrs:{"productID":"2","image":"compresor-2.png","title":"Compresores de tornillo lubricado de acoplamiento directo 1: para la industria en general.","items":[
                            { message: 'Hasta 3,002 CFM (650 hp) Modelos ASD hasta HSD.' }, 
                            { message: 'Hasta 217 psig.' }, 
                            { message: 'Versión “T” con secado integrado hasta 175 hp.' }, 
                            { message: 'Versiones con variador de frecuencia SFC.' }
                        ]}}),_c('product-item',{attrs:{"productID":"3","image":"compresor-3.png","title":"Compresores de tornillo rotativo de 2 etapas secos (libres de aceite) para la industria alimenticia, farmacéutica, electrónica, química, etc.","items":[
                            { message: 'Hasta 1,742 CFM (400 hp) Modelos CSG hasta FSG.' }, 
                            { message: 'Hasta 145 psig.' }, 
                            { message: 'Versiones “T” con secador refrigerativo integrado.' }, 
                            { message: 'Versiones “RD” con secador regenerativo rotativo integrado ( i.HOC).' },
                            { message: 'Versiones con variador de frecuencia SFC.' },
                            { message: 'Certificación Clase”0”.' } ]}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }