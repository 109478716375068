<template>
   <div id="footer">
       <div class="footer--wrapper footer--color d-flex flex-column align-items-center">
           <div class="footer__logo footer__image--margin">
               <a href="#contact-form" v-smooth-scroll="smoothScroolConfig">
                   <img class="footer__image--size" :src="require('@/assets/' + logo)" alt="footer-logo">
               </a>  
           </div>
           <div class="footer__text footer__text--margin w-75">
               <p class="footer__text--style mb-0">Copyright ® 2020 <a href="https://expandit.io/">expand•it</a> - All rights reserved | <a href="https://mx.kaeser.com/privacy.aspx">Aviso de privacidad</a></p>
           </div>
       </div>
   </div>
</template>

<script>
export default {
    name: 'Footer', 
    props: {
        logo: String
    },
    data() {
        return {
            smoothScroolConfig: {   
                duration: 350, 
                offset: -85, 
                container: '', 
                updateHistory: true 
            }
        }
    }
}
</script>

<style scoped>
    /* Text */
    .footer__text--style{
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        transition: font-size 0.3s, font-weight 0.3s;
    }
    .footer__text--color{
        color: var(--ksr-black);
    }
    /* Wrappers */
    .footer--wrapper{
        width: 100%; 
        height: 245px;
        transition: width 0.3s, height 0.3s;
    }
    .footer__image--size{
        width: 92px;
        height: 29px;
    }
    /* Margins */
    .footer__image--margin{
        margin-top: 37px;
    }
    .footer__text--margin{
        margin-top: 20px;
    }
    /* Styles */
    .footer--color{
        background-color: var(--ksr-yellow);
    }
    a, a:hover{
        color: inherit;
    }
    @media (min-width: 576px){
        /* Text */
        .footer__text--style{
            font-size: 18px;
            font-weight: 400;
            transition: font-size 0.3s, font-weight 0.3s;
        }
        /* Wrappers */
        .footer--wrapper{
            width: 100%; 
            height: 147px;
        }
    } 
</style>