<template>
    <div class="custom-carousel">
        <b-carousel
          id="ksr-carousel"
          ref="KsrCarousel"
          v-model="slide"
          :interval="2500"
          img-width="550"
          img-height="310"
          class="custom-carousel--inner-wrapper"
        >
            <b-carousel-slide :img-src="require('@/assets/carousel-2.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('@/assets/carousel-3.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('@/assets/carousel-4.png')"></b-carousel-slide>
        </b-carousel>  
        <div class="d-flex flex-row justify-content-center">
            <div @click="setSlide(0)" :class="(slide == 0) ? 'activeImage':''" class="custom-carousel__indicator custom-carousel__indicator--style  mr-1 d-none d-sm-block"></div>
            <div @click="setSlide(1)" :class="(slide == 1) ? 'activeImage':''" class="custom-carousel__indicator custom-carousel__indicator--style  mr-1 d-none d-sm-block"></div>
            <div @click="setSlide(2)" :class="(slide == 2) ? 'activeImage':''" class="custom-carousel__indicator custom-carousel__indicator--style  mr-1 d-none d-sm-block"></div>
        </div>  
    </div>
</template>

<script>
export default {
    name: 'Carousel', 
    data() {
        return {
            slide: 0
        }
    },
    methods: {
        setSlide(index) {
            this.$refs.KsrCarousel.setSlide(index);
        }
    },
}
</script>

<style>
    /* Wrappers */
    .custom-carousel--inner-wrapper{
        width: 290px;
        height: 200px;
        transition: width 0.3s;
    }
    .custom-carousel__indicator{
        width: 23px; 
        height: 5px;
        cursor: pointer;
    }
    /* Styles */
    .custom-carousel__indicator--style{
        border-radius: 10px;
        background-color: var(--ksr-black);
    }
    .custom-carousel__indicator--style.activeImage{
        background-color: var(--ksr-yellow);
    }
  @media (min-width: 576px) {
        /* Wrappers */
        .custom-carousel--inner-wrapper{
            width: 550px;
            height: 310px;
            transition: width 0.3s;
        }
  }
</style>