<template>
  <div id="app">
    <ksr-dynamic-form/>
    <ksr-header
      logo="logo.svg"
      workDays="Lunes a Viernes"
      workHours="8:00 - 18:00"
      callToAction="Contáctanos"
      tel="(442) 218-6448"
      icon="phone"
    />
    <ksr-contact-section
      mainTitle="¡Los compresores de tornillo de la más alta calidad!"
      mainText="Nuestros equipos te ayudarán a optimizar tu producción desde el primer día, con la máxima disponibilidad y eficiencia energética en aire comprimido."
    />
    <ksr-certifications-section/>
    <ksr-product-list
      title="Catálogo"
      subtitle="Consulta nuestras soluciones en aire comprimido"
      buttonLeftText="Línea productos grandes"
      buttonRightText="Línea productos chicos"
    />
    <ksr-icon-list/>
    <ksr-air-service-section
      title="SAM Sigma Air Manager"
      subtitle="Control, monitoreo, eficiencia, predictividad en la punta de tus dedos."
      text="El SAM 4.0 de KAESER es un controlador maestro y una muestra de la evolución tecnológica desarrollada en la empresa. Ideal para la industria 4.0 que busca mejorar
      la producción y el tratamiento del aire comprimido, optimizando seguridad y eficiencia en tiempo real."
      image="imagen-air.png"
    />
    <ksr-background-section/>
    <ksr-footer
      logo="logo.svg"
    />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  :root{
    --ksr-yellow: #FBB601;
    --ksr-white: #ffffff; 
    --ksr-black: #000000; 
    --ksr-gray: #848484; 
    --ksr-lightGray: #E8E8E8; 
    --ksr-iron: #4F4F4F; 
    --ksr-smoke: #F5F5F5; 
    --ksr-red: #E04A4A;
    --ksr-green: #52CF84;
  }
  #app{
    background-color: var(--ksr-white);
  }
  *{
    font-family: 'Roboto';
    font-style: normal;
  }
</style>
