import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLightbulb, faBolt, faAd,faWrench, faCheckCircle, faExclamationCircle, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueGtag from 'vue-gtag'
import VScrollLock from 'v-scroll-lock'
import store from './store/store'
import VueCarousel from 'vue-carousel';

// Components 
import Header from './components/Header/Header'
import ContactSection from './components/ContactSection/ContactSection'
import CertificationsSection from './components/CertificationsSection/CertificationsSection'
import ProductList from './components/Product/ProductList'
import IconList from './components/Icon/IconList'
import AirServiceSection from './components/AirService/AirServiceSection'
const BackgroundSection = () => import('./components/BackgroundSection/BackgroundSection')
import Footer from './components/Footer/Footer'
import DynamicForm from './components/ContactSection/DynamicForm/DynamicForm'

// Adding Font Awesome 
library.add(faLightbulb, faBolt, faAd,faWrench, faCheckCircle, faExclamationCircle, faPhone);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(vueSmoothScroll);
Vue.use(VScrollLock); 
Vue.use(VueCarousel);
Vue.use(VueGtag, {
  config: { id: "UA-107577183-29" }, 
  includes: [
    { id: "AW-1015462655" },
  ]
});

// Adding global components
Vue.component('ksr-header', Header);
Vue.component('ksr-contact-section', ContactSection);
Vue.component('ksr-certifications-section', CertificationsSection)
Vue.component('ksr-product-list', ProductList);
Vue.component('ksr-icon-list', IconList);
Vue.component('ksr-air-service-section', AirServiceSection);
Vue.component('ksr-background-section', BackgroundSection);
Vue.component('ksr-footer', Footer);
Vue.component('ksr-dynamic-form', DynamicForm);

Vue.config.productionTip = false

new Vue({
  store, 
  render: h => h(App),
}).$mount('#app')
