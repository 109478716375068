<template>
    <div id="product-list">
        <b-container class="product-list--margin">
            <b-row>
                <b-col cols="12">
                    <div class="products-section__title">
                        <h3 class="products-section__title--style">
                            {{ title }}
                        </h3>
                        <h4 class="products-section__subtitle--style"> 
                            {{ subtitle }}
                        </h4>
                    </div>
                    <div class="products-section__list d-flex flex-wrap flew-row justify-content-around px-4">
                        <product-item
                            productID="1"
                            image="compresor-1.png"
                            title="Compresores de tornillo lubricado de transmisión por poleas y bandas para talleres, centros automotrices o pequeños negocios."
                            :items="[
                                { message: 'Hasta 141 CFM (30 hp) Modelos SXC hasta AS.' }, 
                                { message: 'Hasta 217 psig.' }, 
                                { message: 'Versión “T” con secador refrigerativo integrado y Aircenter con secador y tanque integrado.' }, 
                                { message: 'Versiones con variador de frecuencia SFC.' }
                            ]"
                        />
                        <product-item
                            productID="2"
                            image="compresor-2.png"
                            title="Compresores de tornillo lubricado de acoplamiento directo 1: para la industria en general."
                            :items="[
                                { message: 'Hasta 3,002 CFM (650 hp) Modelos ASD hasta HSD.' }, 
                                { message: 'Hasta 217 psig.' }, 
                                { message: 'Versión “T” con secado integrado hasta 175 hp.' }, 
                                { message: 'Versiones con variador de frecuencia SFC.' }
                            ]"
                        />
                        <product-item
                            productID="3"
                            image="compresor-3.png"
                            title="Compresores de tornillo rotativo de 2 etapas secos (libres de aceite) para la industria alimenticia, farmacéutica, electrónica, química, etc."
                            :items="[
                                { message: 'Hasta 1,742 CFM (400 hp) Modelos CSG hasta FSG.' }, 
                                { message: 'Hasta 145 psig.' }, 
                                { message: 'Versiones “T” con secador refrigerativo integrado.' }, 
                                { message: 'Versiones “RD” con secador regenerativo rotativo integrado ( i.HOC).' },
                                { message: 'Versiones con variador de frecuencia SFC.' },
                                { message: 'Certificación Clase”0”.' },
                            ]"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    name: 'ProductList',
    props: {
        title: String, 
        subtitle: String
    },
    components: {
       'product-item': () => import('./ProductItem') 
    }
}
</script>

<style scoped>
    .products-section__title--style{
        font-size: 22px;
        font-weight: 400;
        text-align: center;
    }
    .products-section__subtitle--style{
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }
    .product-list--margin{
        margin-top: 113px;
        transition: margin-top 0.3s;
    }
    @media (min-width: 992px) {
        .product-list--margin{
            margin-top: 155px;
            transition: margin-top 0.3s;
        }
    }
</style>