<template>
    <div class="air-service-section">
        <b-container class="air-service--margin">
            <b-row>
                <b-col cols="12" lg="7">
                    <div class="air-service__title mt-5 mb-5">
                        <h3 class="air-service__title--style air-service__title--color">
                            {{ title }}
                        </h3>
                    </div>
                    <div class="air-service__subtitle mb-3">
                        <h4 class="air-service__text--style air-service__text--color px-4 px-md-0">
                            {{ subtitle }}
                        </h4>
                    </div>
                    <div class="air-service__text">
                        <p class="air-service__text--style air-service__text--color px-4 px-md-0">
                            {{ text }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-center justify-content-md-start mt-4">
                        <a class="mb-4" href="#contact-form" @click="eventButton" v-smooth-scroll="smoothScroolConfig">
                            <div class="product-card__button product-card__button--style d-flex align-items-center justify-content-center">
                                <p class="m-0 text-center">Comienza a usar la tecnología 4.0</p>    
                            </div> 
                        </a>
                    </div>
                </b-col>
                <b-col cols="12" lg="5">
                    <div class="air-service__image mt-5 d-flex justify-content-center justify-content-lg-start">
                        <img class="air-service__image--size" :src="require('@/assets/' + image)" alt="air-service">
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'AirServiceSection', 
    props: {
        title: String,
        subtitle: String,
        text: String,
        image: String    
    }, 
    data() {
        return {
            smoothScroolConfig: {   
                duration: 350, 
                offset: -85, 
                container: '', 
                updateHistory: true 
            }
        }
    },
    methods: {
        eventButton: function() {
            this.$store.dispatch('clickingCTA', 'SAM: Comienza a usar la tecnología 4.0');
             this.$gtag.event('Click', {
                'event_category': 'SAM',
                'event_label': "Comienza a usar la tecnología 4.0",
            })
        }
    } 
}
</script>

<style scoped>
    /* Text */
    .air-service__title--style{
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        transition: font-size 0.3s, text-align 0.3s;
    }
    .air-service__text--style{
        font-size: 16px;
        font-weight: 300;
        line-height: 220%;
        text-align: center;
        transition: font-size 0.3s, line-height 0.3s, text-align 0.3s;
    }
    .air-service__text--color{
        color: var(--ksr-iron);
    }
    .air-service__title--color{
       color: var(--ksr-yellow);
    }
    /* Wrappers */
    .air-service__image--size{
        width: 200px;
        height: 153px;
        transition: width 0.3s, height 0.3s;
    }
    .product-card__button{
        width: 270px;
        height: 40px;
    }
    /* Margins */
    .air-service--margin{
        margin-top: 37px; 
        margin-bottom: 50px;
        transition: margin-top 0.3s, margin-bottom 0.3s;
    }
    /* Styles */ 
    .product-card__button--style{
        color: var(--ksr-black);
        background-color: var(--ksr-yellow);
        transition: background-color 0.3s, color 0.3s;
    }
    .product-card__button--style:hover{
        color: var(--ksr-white);
        background-color: var(--ksr-gray);
        transition: background-color 0.3s, color 0.3s;
    }
    a{
        text-decoration: none;
    }
    @media (min-width: 576px){ 
        /* Wrappers */
        .air-service__image--size{
            width: 295.5px;
            height: 206px;
            transition: width 0.3s, height 0.3s;
        } 
    }
    @media (min-width: 768px){ 
        /* Text */
        .air-service__title--style{
            font-size: 26px;
            text-align: left;
            transition: font-size 0.3s, text-align 0.3s;
        }
        .air-service__text--style{
            font-size: 16px;
            line-height: 200%;
            text-align: left;
            transition: font-size 0.3s, line-height 0.3s, text-align 0.3s;
        }  
        /* Margins */
        .air-service--margin{
            margin-top: 150px; 
            margin-bottom: 110px;
            transition: margin-top 0.3s, margin-bottom 0.3s;
        }
    }
</style>