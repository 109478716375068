<template>
    <div class="dynamic-form d-none d-sm-block">
        <transition name="entrance">
            <div v-if="showButton" @scroll="handleScroll" @click="handleButton" class="dynamic-form__button dynamic-form__button--position dynamic-form__button--color d-flex justify-content-center align-items-center">
                <p class="dynamic-form__button--text dynamic-form__button--text-color m-0">Contáctanos</p>
            </div>
        </transition>
        <transition name="form-entrance">
             <div v-if="buttomClicked" class="dynamic-form__form-position">
                <ksr-contact-form
                     title="Contáctanos"
                     subtitle="Nos comunicaremos contigo a la brevedad"
                     button="Enviar"
                     sentTitle="¡Gracias por su preferencia!"
                     sentText="Nos comunicaremos contigo a la brevedad"
                ></ksr-contact-form>
             </div>
        </transition>
        <div v-if="buttomClicked" @click="handleModal" v-scroll-lock="buttomClicked" class="dynamic-form__modal dynamic-form__modal--position dynamic-form__modal--color"></div>
    </div>
</template>

<script>
import ContactForm from '../ContactForm/ContactForm'

export default {
    name: 'DynamicForm',
    components: {
        'ksr-contact-form': ContactForm,
    },
    data() {
        return {
            showButton: false, 
            scrollpositon: null, 
            buttomClicked: false,
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    }, 
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }, 
    methods: {
        handleScroll: function() {
            this.scrollpositon = window.scrollY; 
            var component = document.getElementById('certifications-section'); 
            var finalComponent = document.getElementById('footer'); 
            var componentPosition = component.offsetTop - 85; 
            var finalComponentPosition = 120 + finalComponent.offsetTop - window.screen.height; 
            if(this.scrollpositon > componentPosition && this.scrollpositon < finalComponentPosition) {
                this.showButton = true; 
            }
            else {
                this.showButton = false; 
            }      
        },
        handleButton: function() {
            this.buttomClicked = true; 
            this.eventButton(); 
        }, 
        handleModal: function() {
            this.buttomClicked = false; 
        },
        eventButton: function() {
             this.$gtag.event('Click', {
                'event_category': 'Dynamic Form Button',
                'event_label': "Contáctanos",
            })
        }
    }, 
}
</script>

<style scoped>
    /* text */ 
    .dynamic-form__button--text{
        font-weight: 500;
        font-size: 22px;
    }
    .dynamic-form__button--text-color{
        color: var(--ksr-white);
    }
    /* Wrappers */ 
    .dynamic-form__button{
        width: 382px;
        height: 60px;
        cursor: pointer;
    }
    .dynamic-form__modal{
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
     /* Position */ 
    .dynamic-form__button--position{
        position: fixed;
        bottom: 0px;
        right: 0%;
        z-index: 1;
        transition: right 0.3s;
        transform: translateX(0%);
    }
    .dynamic-form__form-position{
        position: fixed;
        bottom: 0px;
        right: 0%;
        z-index: 3;
        transition: right 0.3s;
        transform: translateX(0%);
    }
    .dynamic-form__modal--position{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
    }
    /* Colors */
    .dynamic-form__button--color{
        background-color: var(--ksr-yellow);
    }
     .dynamic-form__modal--color{
        background-color: rgba(0, 0, 0, 0.4);
    }
    /* Animations */
    .form-entrance-leave-active{
        animation: form-entrance 0.3s reverse;
    }
    .form-entrance-enter-active{
        animation: form-entrance 0.3s;
    }
    @keyframes form-entrance {
        from { bottom: -484px;}
        to { bottom: 0px;}
    }
    .entrance-enter-active{
        animation: entrance 0.3s;
    }
    .entrance-leave-active{
        animation: entrance 0.3s reverse;
    }
    @keyframes entrance {
        from { bottom: -60px;}
        to { bottom: 0px;}
    }
    @media (min-width: 992px) {
        .dynamic-form__button--position{
            right: 0%;
            transform: translateX(0);
            transition: right 0.3s;
        }
        .dynamic-form__form-position{
            right: 0%;
            transform: translateX(0%);
            transition: right 0.3s;
        }
    }
</style>