import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      isFormLoading: false, 
      wasFormSent: false, 
      lastClick: null, 
    }, 
    mutations: {
      sendingForm(state) {
        state.isFormLoading = true; 
      }, 
      formSent(state) {
        state.wasFormSent = true; 
      }, 
      clickingCTA(state, sectionID) {
        state.lastClick = sectionID; 
      }
    }, 
    actions: {
        sendingForm(context) {
            context.commit('sendingForm'); 
        }, 
        formSent(context) {
            context.commit('formSent'); 
        }, 
        clickingCTA(context, sectionID) {
          context.commit('clickingCTA', sectionID); 
        }
    }, 
    getters: {
      getIsFormLoading: state => {
        return state.isFormLoading; 
      }, 
      getWasFormSent: state => {
        return state.wasFormSent; 
      }, 
      getLastClick: state => {
        return state.lastClick; 
      }
    }
  }); 